const { Mask, MaskInput, vMaska } = Maska;
new MaskInput("[data-maska]");

const toast = {
    duration: 3000,
    gravity: "top", // `top` or `bottom`
    position: "center", // `left`, `center` or `right`
    stopOnFocus: true, // Prevents dismissing of toast on hover
    style: {
        background: "#0d6efd",
        marginTop: "80px",
    },
};

function loading(show = true) {
    const button = document.getElementById("btn-contact-form-submit");
    if (show) {
        button.classList.add("loading");
    } else {
        button.classList.remove("loading");
    }
}

const app = Vue.createApp({
    data() {
        return {
            name: "",
            phone: "",
            email: "",
            message: "",
            nameError: "",
            phoneError: "",
            emailError: "",
            messageError: "",
            disabled: false,
        };
    },
    directives: { maska: vMaska },
    methods: {
        validateForm() {
            this.nameError = this.name ? "" : "O 'Nome' é obrigatório";
            this.phoneError = this.phone ? "" : "O 'Telefone' é obrigatório";
            if (this.phone && this.phone.replace(/\D/g, "").length < 11) {
                this.phoneError = "O 'Telefone' está incompleto";
            }
            const emailRegex = /^\S+@\S+\.\S+$/;
            this.emailError = this.email ? "" : "O 'Email' é obrigatório";
            if (this.email && !emailRegex.test(this.email)) {
                this.emailError = "O 'Email' está inválido ";
            }
            this.messageError = this.message ? "" : "A 'Mensagem' é obrigatória";
            return !this.nameError && !this.phoneError && !this.emailError && !this.messageError;
        },
        submitForm() {
            if (!this.validateForm()) {
                return;
            }
            this.disabled = true;
            loading();
            const payload = {
                subject: "Nova mensagem do form de contato do site da Clínica Reabilit",
                name: this.name,
                phone: this.phone,
                email: this.email,
                message: this.message,
            };
            axios
                .post("https://formspree.io/f/mayrzpqz", payload)
                // .post("https://run.mocky.io/v3/47ef3957-607c-4f93-859e-3f4dd8b05dfc", payload)
                .then((response) => {
                    Toastify({ ...toast, text: "Sua mensagem foi enviada com sucesso!" }).showToast();
                    loading(false);
                })
                .catch((error) => {
                    console.error(error);
                    this.disabled = false;
                    Toastify({
                        ...toast,
                        style: { ...style, background: "#dc3545" },
                        duration: 10000,
                        text: "Ocorreu um erro no envio da sua mensagem. Por favor, recarregue a página e tente novamente",
                    }).showToast();
                });
        },
    },
});
app.mount("#section-contact");
